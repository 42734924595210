import { formatTime } from "@clipboard-health/date-time";
import { Text } from "@clipboard-health/ui-react";
import { type Shift } from "@src/appV2/Shifts/Shift/types";
import { differenceInMinutes, parseISO } from "date-fns";
import pluralize from "pluralize";

export function getShiftDuration(shift: Pick<Shift, "start" | "end">): string {
  const startTime = parseISO(shift.start);
  const endTime = parseISO(shift.end);

  const durationInMinutes = differenceInMinutes(endTime, startTime);
  const durationInHours = durationInMinutes / 60;
  const isLunchBreakIncludedInShift = durationInHours > 6;

  // If the shift includes breaks, we remove half an hour from the total duration
  const realDurationInHours = isLunchBreakIncludedInShift ? durationInHours - 0.5 : durationInHours;

  const durationHours = Math.floor(realDurationInHours);
  const durationMinutes = Math.round((realDurationInHours - durationHours) * 60);

  let duration = `${durationHours} ${pluralize("hr", durationHours)}`;

  if (durationMinutes > 0) {
    duration += ` ${durationMinutes} ${pluralize("min", durationMinutes)}`;
  }

  return duration;
}

interface ShiftDurationProps {
  shift: Pick<Shift, "start" | "end">;
  timeZone?: string;
  isEmphasized?: boolean;
}

export function ShiftDuration(props: ShiftDurationProps) {
  const { shift, timeZone, isEmphasized = true } = props;
  const duration = getShiftDuration(shift);

  const shiftStart = formatTime(shift.start, { timeZone });
  const shiftEnd = formatTime(shift.end, { timeZone });

  return (
    <Text bold={isEmphasized} variant={isEmphasized ? "body1" : "body2"}>
      {shiftStart} - {shiftEnd} ({duration})
    </Text>
  );
}
