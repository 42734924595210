import { isDefined } from "@clipboard-health/util-ts";
import { addDays, endOfDay, isAfter, isBefore } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
// TODO: As is shown in this PR: https://github.com/ClipboardHealth/cbh-mobile-app/pull/5802,
//       date-fns-tz was not working as expected for calculating offsets while taking DST into account.
//       Using moment-timezone here for now until we find a better solution (ideally we upgrade date-fns-tz to the latest version)
// eslint-disable-next-line no-restricted-imports
import moment from "moment-timezone";

import { type Shift } from "../Shift/types";

export function formatRate(amount?: number): string {
  if (!isDefined(amount)) {
    return "-";
  }

  const rateFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return rateFormatter.format(amount);
}

export function getBookingSchedule(
  prefix: "Shift" | "Interview",
  /**
   * start date in user's timezone
   */
  startDate: Date,
  /**
   * end date in user's timezone
   */
  endDate: Date,
  /**
   * user's timezone
   */
  timezone: string
): string | undefined {
  const now = utcToZonedTime(new Date(), timezone);
  const tomorrow = addDays(now, 1);

  if (isAfter(now, startDate) && isBefore(now, endDate)) {
    return `${prefix} In Progress`;
  }

  if (isBefore(now, startDate) && isBefore(startDate, endOfDay(now))) {
    return "Today";
  }

  if (isBefore(endOfDay(now), startDate) && isBefore(startDate, endOfDay(tomorrow))) {
    return "Tomorrow";
  }

  return undefined;
}

function getShiftTimezoneOffsetInHours({
  shift,
  timeZone,
}: {
  shift: Pick<Shift, "start" | "end">;
  timeZone: string;
}) {
  const momentZone = moment.tz.zone(timeZone);

  if (!momentZone) {
    return undefined;
  }

  const offsetStartMinutes = -momentZone.utcOffset(moment(shift.start).valueOf());
  const offsetEndMinutes = -momentZone.utcOffset(moment(shift.end).valueOf());

  return (offsetStartMinutes - offsetEndMinutes) / 60;
}

function getUnity(offsetInHours: number) {
  if (offsetInHours < 1) {
    return "mins";
  }

  if (offsetInHours === 1) {
    return "hr";
  }

  return "hrs";
}

function getHumanReadableOffset(offset: number) {
  const direction = offset > 0 ? "longer" : "shorter";
  const offsetInHours = Math.abs(offset);

  if (offsetInHours < 1) {
    return `${offsetInHours * 60} mins ${direction}`;
  }

  const unity = getUnity(offsetInHours);
  return `${offsetInHours} ${unity} ${direction}`;
}

export function getDaylightSavingsTimeIndicator({
  shift,
  timeZone,
}: {
  shift: Pick<Shift, "start" | "end">;
  timeZone: string;
}) {
  const offsetDifference = getShiftTimezoneOffsetInHours({ shift, timeZone });

  if (!isDefined(offsetDifference) || offsetDifference === 0) {
    return undefined;
  }

  const humanReadableOffset = getHumanReadableOffset(offsetDifference);
  return `${humanReadableOffset} for DST change`;
}
